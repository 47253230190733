import {
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Paper,
  Portal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../data/store";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { TransportSelect } from "../../filters/TransportSelect";
import { DepotSelect } from "../../filters/DepotSelect";
import { DatePicker } from "@mui/x-date-pickers";
import { QueryError } from "../QueryError";
import { AutoColumnSelect } from "../../filters/AutoColumnSelect";
import { useGetTimetablesByDateQuery } from "../../data/api/hooks";
import {
  DayExplPlan,
  DayLineTimetableColumns,
  LineTimetable,
  LineTimetableData,
  createLineTimetableData,
  useVehicleGarageTypes,
} from "./useTimetables_ByDate";

import { TimetbaleVehicletypeEditForm } from "./TimetableVehicletype_EditForm";
import { updateSelectedDate } from "../../data/filtersSlice";
import { useSubmenu } from "../../hooks/useSubmenu";
import { Print } from "@mui/icons-material";
import { usePrintTimetableByDatePdfExporter } from "../../printing/useTimetableByDatePrintExporter";

function StickyHeadTable({
  rows,
  setEditFormVisible,
  setSelectedLineTimetable,
  data,
}: {
  rows: { [key: string]: LineTimetableData[] };
  setEditFormVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedLineTimetable: React.Dispatch<
    React.SetStateAction<LineTimetable | undefined>
  >;
  data: DayExplPlan;
}) {
  return (
    <Paper sx={{ height: "100%" }}>
      <TableContainer sx={{ height: "100%" }}>
        <Table stickyHeader aria-label="stickyHeader">
          <TableHead>
            <TableRow>
              {DayLineTimetableColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(rows).map(
              ([key, item], idx) =>
                rows[key].length > 0 && (
                  <React.Fragment key={idx}>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        {key}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} colSpan={8}>
                        {item[0] ? item[0].tt_code : ""}
                      </TableCell>
                    </TableRow>

                    {item.map((r, idx) => (
                      <TableRow
                        key={idx}
                        style={{
                          backgroundColor:
                            r.shifts.length > 1 && r.nightCar
                              ? "#ff00001a"
                              : "white",
                        }}
                      >
                        <TableCell>{r.car_no}</TableCell>
                        <TableCell>
                          <Link
                            component="button"
                            //variant="body2"
                            //   onClick={() => {
                            //     console.info("I'm a button.");
                            //   }}
                            // >
                            //                           <Button
                            //                             variant="contained"
                            //                             // color="primary"
                            onClick={() => {
                              setSelectedLineTimetable(
                                data.expl_plan.find(
                                  (e) =>
                                    e.line === r.line_id && e.code === r.tt_code
                                )
                              );
                              setEditFormVisible(true);
                            }}
                          >
                            {r.vehicletype_name
                              ? r.vehicletype_name
                              : "Неопределен"}
                          </Link>
                        </TableCell>
                        {r.shifts
                          .sort((s1, s2) =>
                            s1.start_time > s2.start_time ? 1 : -1
                          )
                          .map((s, idx) => (
                            <React.Fragment key={idx}>
                              <TableCell align="center">
                                {s.start_time}
                              </TableCell>
                              <TableCell align="center">{s.end_time}</TableCell>
                              <TableCell align="center">
                                {s.start_time_2 !== "00:00" ? (
                                  <></>
                                ) : (
                                  s.work_hours
                                )}
                              </TableCell>
                              {s.start_time_2 !== "00:00" && (
                                <React.Fragment>
                                  <TableCell align="center">
                                    {s.start_time_2}
                                  </TableCell>
                                  <TableCell align="center">
                                    {s.end_time_2}
                                  </TableCell>
                                  <TableCell align="center">
                                    {s.work_hours}
                                  </TableCell>
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          ))}
                        {r.shifts.length <= 1 &&
                          !r.singleShift && ( // допълвам чертите на реда
                            <React.Fragment>
                              <TableCell />
                              <TableCell />
                              <TableCell />
                            </React.Fragment>
                          )}

                        <TableCell align="center">
                          {" "}
                          {r.singleShift ? "да" : "не"}{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export function Timetables_ByDateReport() {
  const needsTransportSelect = useSelector(
    (state: RootState) => state.filters.needsTransportSelect
  );
  const needsDepotSelect = useSelector(
    (state: RootState) => state.filters.needsDepotSelect
  );
  const needsAutocolumnSelect = useSelector(
    (state: RootState) => state.filters.needsAutocolumnSelect
  );

  const selectedDate = useSelector((state: RootState) =>
    state.filters.selectedDate
      ? state.filters.selectedDate
      : moment().add(1, "day").startOf("day").format("YYYY-MM-DD")
  );

  const dispatch = useAppDispatch();

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const [editFormVisible, setEditFormVisible] = useState(false);
  const [selectedLineTimetable, setSelectedLineTimetable] = useState<
    LineTimetable | undefined
  >(undefined);

  const { data, isLoading, isError } = useGetTimetablesByDateQuery(
    {
      transport: selectedTransport,
      garage: selectedDepot,
      avtocolona: selectedAvtoColumn,
      date: selectedDate,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !(selectedDepot !== -1 && selectedTransport !== ""),
    }
  );

  const rows = useMemo<{ [key: string]: LineTimetableData[] }>(() => {
    if (data?.expl_plan && data?.expl_plan.length > 0) {
      const arr: { [key: string]: LineTimetableData[] } = {};
      for (const line of Object.values(data.expl_plan).sort((a, b) =>
        a.line_order > b.line_order ? 1 : -1
      )) {
        const arr_element = line.cars
          .filter((c) =>
            c.workshifts.length > 0
              ? c.workshifts[0].autocolumn === selectedAvtoColumn
              : //||                c.workshifts[0].autocolumn == null
              false
          )
          .map((car) =>
            createLineTimetableData(
              line.line,
              line.line_name,
              line.code,
              car.car_no,
              car.workshifts[0].vehicletype
                ? car.workshifts[0].vehicletype.description
                : "",
              car.workshifts.map((ws) => ({
                start_time: ws.start_time,
                end_time: ws.end_time,
                shift_type: ws.shift_type,
                start_time_2: ws.start_time_2,
                end_time_2: ws.end_time_2,
              }))
            )
          );
        arr[line.line_name] = arr_element;
      }
      return arr;
    } else {
      return {};
    }
  }, [data?.expl_plan, selectedAvtoColumn]);

  const totalShift1Count = useMemo(() => {
    if (data?.expl_plan && data?.expl_plan.length > 0) {
      let count = 0;
      for (const line of Object.values(data.expl_plan)) {
        const line_cnt = line.cars
          .filter((c) =>
            c.workshifts.length > 0
              ? c.workshifts[0].autocolumn === selectedAvtoColumn
              : false
          )
          .reduce(
            (ac, car) =>
            (ac += car.workshifts.reduce(
              //(acc, w) => (w.start_time < SHIFT2_SECONDS ? (acc += 1) : acc),
              (acc, w) => (w.shift_type === 1 ? (acc += 1) : acc),
              0
            )),
            0
          );
        count += line_cnt;
      }
      return count;
    } else {
      return 0;
    }
  }, [data?.expl_plan, selectedAvtoColumn]);

  const totalShift2Count = useMemo(() => {
    if (data?.expl_plan && data?.expl_plan.length > 0) {
      let count = 0;
      for (const line of Object.values(data.expl_plan)) {
        const line_cnt = line.cars
          .filter((c) =>
            c.workshifts.length > 0
              ? c.workshifts[0].autocolumn === selectedAvtoColumn
              : false
          )
          .reduce(
            (ac, car) =>
            (ac += car.workshifts.reduce(
              //  (acc, w) => (w.start_time >= SHIFT2_SECONDS ? (acc += 1) : acc),
              (acc, w) => (w.shift_type > 1 ? (acc += 1) : acc),
              0
            )),
            0
          );
        count += line_cnt;
      }
      return count;
    } else {
      return 0;
    }
  }, [data?.expl_plan, selectedAvtoColumn]);

  //console.log("vehicle", vehicles.currentData);
  const { vehicleTypeList } = useVehicleGarageTypes();
  // useMemo(
  //   () =>
  //     vehicleTypesRecord && vehicles?.currentData
  //       ? Object.values(vehicleTypesRecord)
  //           .filter((vt) =>
  //             vehicles?.currentData?.data
  //               ? vehicles.currentData.data.filter(
  //                   (vt1) => vt1.vehicletype_id === vt.id
  //                 ).length > 0
  //               : false
  //           )
  //           .map((v) => ({
  //             label: v.description,
  //             id: v.id,
  //           }))
  //       : [],

  //   [vehicleTypesRecord, vehicles.currentData]
  // );

  // console.log("Data:", data);
  // console.log("Rows:", rows);
  // console.log("currentData", vehicleTypesRecord);

  const { rightToolbarMenu } = useSubmenu();

  const { exportPdf, isLoading: exportPdfLoading } =
    usePrintTimetableByDatePdfExporter();

  return (
    <>
      <Portal container={rightToolbarMenu}>
        <IconButton
          color="inherit"
          size="large"
          aria-label="Експорт в PDF"
          title="Експорт в PDF"
          disabled={exportPdfLoading}
          onClick={() => exportPdf(rows, selectedDate)}
        >
          {exportPdfLoading ? (
            <CircularProgress
              size="0.75em"
              sx={{ color: (theme) => theme.palette.grey[100] }}
            />
          ) : (
            <Print />
          )}
        </IconButton>
      </Portal>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container>
          <Grid container spacing={2} mt={1}>
            {needsTransportSelect ? (
              <Grid item xs={12} sm={3}>
                <TransportSelect />
              </Grid>
            ) : (
              <></>
            )}
            {needsDepotSelect ? (
              <Grid item xs={12} sm={3}>
                <DepotSelect />
              </Grid>
            ) : (
              <></>
            )}
            {needsAutocolumnSelect ? (
              <Grid item xs={12} sm={2}>
                <AutoColumnSelect />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} sm={2}>
              <DatePicker
                value={moment(selectedDate)}
                onChange={(e) =>
                  e &&
                  e.isValid() &&
                  dispatch(updateSelectedDate(e.format("YYYY-MM-DD")))
                }
                label="Дата"
                views={["year", "month", "day"]}
                openTo="day"
                slotProps={{
                  textField: {
                    variant: "standard",
                    sx: { m: 1, width: "100%" },
                  },
                }}
              />
            </Grid>
          </Grid>

          {data?.expl_plan && rows && !isError && !isLoading && (
            <Grid container mt={1} mb={3} spacing={2}>
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                sx={{ px: 4, py: 2, ml: 1, mr: -1, my: 1 }}
              >
                {/* <Paper sx={{ px: 4, py: 2, ml: 1, mr: -1, my: 1 }}> */}
                Първи смени: {totalShift1Count}
                {/* </Paper> */}
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                sx={{ px: 4, py: 2, ml: 1, mr: -1, my: 1 }}
              >
                {/* <Paper sx={{ px: 4, py: 2, ml: 1, mr: -1, my: 1 }}> */}
                Втори смени: {totalShift2Count}
                {/* </Paper> */}
              </Grid>
            </Grid>
          )}

          {isError && <QueryError sx={{ mt: 2, mb: 3, mx: 1 }} />}
        </Container>

        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Backdrop
            open={isLoading}
            sx={{
              color: "#fff",
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress
              sx={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 1,
                marginBottom: 4,
              }}
            />
          </Backdrop>
          <Container sx={{ height: "100%" }}>
            {rows && !isError && data && (
              <StickyHeadTable
                rows={rows}
                setEditFormVisible={setEditFormVisible}
                setSelectedLineTimetable={setSelectedLineTimetable}
                data={data}
              />
            )}
          </Container>
        </Box>

        {selectedTransport !== "" &&
          rows &&
          !isError &&
          selectedLineTimetable && (
            <TimetbaleVehicletypeEditForm
              open={editFormVisible}
              setOpen={setEditFormVisible}
              selectedLineTimetable={selectedLineTimetable}
              setSelectedLineTimetable={setSelectedLineTimetable}
              vehicleTypeList={vehicleTypeList}
              selectedTransport={selectedTransport}
              selectedGarage={selectedDepot}
              selectedDate={selectedDate}
            />
          )}
      </Box>
    </>
  );
}
