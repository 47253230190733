import {
  Grid,
  Backdrop,
  CircularProgress,
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetDriversListQuery,
  useGetDriversScheduleHistoryDayQuery,
  useGetDriversScheduleHistoryQuery,
} from "../../../data/api/hooks";
import { RootState, useAppDispatch } from "../../../data/store";
import { AutoColumnSelect } from "../../../filters/AutoColumnSelect";
import { DepotSelect } from "../../../filters/DepotSelect";
import { TransportSelect } from "../../../filters/TransportSelect";
import { QueryError } from "../../QueryError";
import {
  ScheduleHistoryColumns,
  ScheduleHistoryData,
  createReportHistoryData,
} from "./useSchedule_history";
import { MonthPicker } from "../../../filters/MonthPicker";
import { DatePicker } from "@mui/x-date-pickers";
import { updateSelectedDate } from "../../../data/filtersSlice";

function StickyHeadTable({ rows }: { rows: ScheduleHistoryData[] }) {
  return (
    <Paper sx={{ width: "100%", height: "100%" }}>
      <TableContainer sx={{ height: "100%" }}>
        <Table stickyHeader aria-label="stickyHeader">
          <TableHead>
            <TableRow>
              {ScheduleHistoryColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  //style={{ width: column.width }}
                  sx={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => {
              return (
                <TableRow hover tabIndex={-1} key={idx}>
                  <TableCell align="center">{row.driver_id}</TableCell>

                  <TableCell align="center">
                    {moment(row.day).format("LL")}
                  </TableCell>
                  <TableCell align="center">{row.state}</TableCell>
                  <TableCell align="center">
                    {moment(row.updated_at).format("LLL")}
                  </TableCell>
                  <TableCell align="center">{row.username}</TableCell>
                  {/* <TableCell align="center">
                          {moment
                            .utc(
                              moment
                                .duration(row.start_shitf, "seconds")
                                .as("milliseconds")
                            )
                            .format("HH:mm")}
                        </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export function ScheduleHistoryReport() {
  const needsTransportSelect = useSelector(
    (state: RootState) => state.filters.needsTransportSelect
  );
  const needsDepotSelect = useSelector(
    (state: RootState) => state.filters.needsDepotSelect
  );
  const needsAutocolumnSelect = useSelector(
    (state: RootState) => state.filters.needsAutocolumnSelect
  );

  //const [selectedDate, setSelectedDate] = useState<Moment>(moment());

  const selMonth = useSelector(
    (state: RootState) => state.filters.selectedMonth
  );

  const selectedMonth = useMemo(
    () => (selMonth && selMonth !== "" ? moment(selMonth) : null),
    [selMonth]
  );

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const selectedDepot = useSelector(
    (state: RootState) => state.filters.selectedDepot
  );

  const selectedAvtoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const [selectedDriver, setSelectedDriver] = useState<{
    id: number;
    label: string;
  } | null>(null);

  useEffect(() => {
    setSelectedDriver(null);
  }, [selectedAvtoColumn, selectedDepot, selectedTransport]);

  const {
    data: dataDrivers,
    isLoading: isDriversLoading,
    isError: isDriversError,
  } = useGetDriversListQuery(
    { transport: selectedTransport, garage: selectedDepot },
    {
      refetchOnMountOrArgChange: 1,
      skip: !(selectedTransport !== "" && selectedDepot !== -1),
    }
  );

  const drivers = useMemo(() => {
    setSelectedDriver(null);
    return dataDrivers?.drivers
      .filter((d) => d.avtocolona === selectedAvtoColumn && d.state <= 2)
      .map((d) => ({ label: d.sl_number.toString(), id: d.sl_number }));
  }, [dataDrivers?.drivers, selectedAvtoColumn]);

  const selectedDate = useSelector((state: RootState) =>
    state.filters.selectedDate
      ? state.filters.selectedDate
      : moment().add(1, "day").startOf("day").format("YYYY-MM-DD")
  );

  const dispatch = useAppDispatch();

  const getDriversScheduleQueryData = useMemo(
    () => ({
      transport: selectedTransport,
      garage: selectedDepot,
      avtocolona: selectedAvtoColumn,
      date: selectedDate,
      sl_number: selectedDriver ? selectedDriver.id : null,
    }),
    [
      selectedAvtoColumn,
      selectedDate,
      selectedDepot,
      selectedDriver,
      selectedTransport,
    ]
  );

  const { data, isLoading, isFetching, isError } =
    useGetDriversScheduleHistoryDayQuery(getDriversScheduleQueryData, {
      refetchOnMountOrArgChange: true,
      skip: !(
        selectedDepot !== -1 &&
        selectedTransport !== "" &&
        selectedDriver !== null
      ),
    });

  const rows = useMemo(() => {
    let arr: ScheduleHistoryData[] = [];

    if (data?.data?.drivers_schedule && selectedDriver) {
      for (const drv_schedule of data.data.drivers_schedule.filter(
        (d) =>
          d.driver_days.filter((dd) => dd.history_days?.length > 0).length > 0
      )) {
        for (const element of drv_schedule.driver_days.filter(
          (dd) => dd.history_days.length >= 2
        )) {
          const rows_data = element.history_days
            .map((y) =>
              createReportHistoryData(
                drv_schedule.sl_number,
                y.day_in_month,
                y.state_name,
                y.updated_at,
                y.username ? y.username?.name : ""
              )
            )
            .sort((s1, s2) =>
              moment(s1.updated_at) > moment(s2.updated_at) ? 1 : -1
            );
          arr = arr.concat(rows_data);
        }
      }
    }

    return arr;
  }, [data?.data?.drivers_schedule, selectedDriver]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container>
        <Grid container spacing={2} mt={1}>
          {needsTransportSelect ? (
            <Grid item xs={12} sm={3}>
              <TransportSelect />
            </Grid>
          ) : (
            <></>
          )}
          {needsDepotSelect ? (
            <Grid item xs={12} sm={3}>
              <DepotSelect />
            </Grid>
          ) : (
            <></>
          )}
          {needsAutocolumnSelect ? (
            <Grid item xs={12} sm={2}>
              <AutoColumnSelect />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} sm={2}>
            <Autocomplete
              sx={{ m: 1 }}
              key={selectedDriver?.id}
              value={selectedDriver ? selectedDriver : undefined}
              options={drivers ? drivers : []}
              renderInput={(params) => (
                <TextField {...params} label="Водач" variant="standard" />
              )}
              onChange={(_, data) => {
                if (data) setSelectedDriver(data);
              }}
              noOptionsText={""}
              // getOptionLabel={(option) => (option ? option.label : "")}
              //isOptionEqualToValue={(option, value) => option === value}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              autoSelect
              disabled={isDriversLoading}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <DatePicker
              value={moment(selectedDate)}
              onChange={(e) =>
                e &&
                e.isValid() &&
                dispatch(updateSelectedDate(e.format("YYYY-MM-DD")))
              }
              label="Дата"
              views={["year", "month", "day"]}
              openTo="day"
              slotProps={{
                textField: {
                  variant: "standard",
                  sx: { m: 1, width: "100%" },
                },
              }}
            />
          </Grid>
          {/* <Grid item xs={12} sm={2}>
            <MonthPicker />
          </Grid> */}
        </Grid>

        {isError && <QueryError sx={{ mt: 2, mb: 3, mx: 1 }} />}
      </Container>

      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Backdrop
          open={isLoading}
          sx={{
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress
            sx={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 1,
              marginBottom: 4,
            }}
          />
        </Backdrop>
        <Container sx={{ py: 4 }}>
          {rows &&
            !isError &&
            !isLoading &&
            !isFetching &&
            data?.data.daytask_states &&
            data.data.drivers_schedule.length > 0 && (
              <StickyHeadTable rows={rows} />
            )}
          {!isError && data?.data.drivers_schedule.length === 0 && (
            <div>
              <Alert severity="info"> Няма данни за избраната дата!</Alert>
            </div>
          )}
        </Container>
      </Box>
    </Box>
  );
}
